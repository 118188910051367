import axios from 'axios'

// 标注了自定义的 可以自己先写死地址和token测试下，很快的
export function newExportsExcel (url, xlsName) {
    let d = new Date()
    let month= (d.getMonth() + 1)
    let day = d.getDate()
    let time = d.getFullYear() + '-' + (String(month).length > 1 ? month : '0' + month) + '-' + (String(day).length > 1 ? day : '0' + day)
    //这是请求的地址 （自定义）
    let _herf = process.env.VUE_APP_BASE_URL
    // 这是请求头中需要的token （自定义）
    let token = localStorage.getItem('token')
    this.$loading.show()
    axios.get(`${_herf}${url}`, {
        responseType: 'blob',
        headers: {
            'dqAdmin-token': token,
            // 这是格式，看后段要不要你传 （自定义）
            ContentType:'application/json'
        },
    }).then(res => {
        this.$loading.hide()
        let blob = res.data
        let reader = new FileReader()
        reader.readAsDataURL(blob)
        reader.onload = (e) => {
            let a = document.createElement('a')
            a.download = xlsName + time + '.xls'
            a.href = e.target.result
            document.body.appendChild(a)
            a.click()
            document.body.removeChild(a)
        }
    }).catch(err => {
        this.$loading.hide()
        this.$message.err(`${err}`)
    })
}