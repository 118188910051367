<template>
  <a-modal v-model="showModel" :width='600' :title="titleName" cancel-text="取消" @cancel="cancelPushModal">
    <div class="push-modal">
      <div>
        <a-range-picker
            v-if="showModel"
            :placeholder="['统计开始日期','统计结束日期']"
            class="w-300"
            :disabledDate="disabledDate"
            :locale="locale"
            @change="handleDownloadChangeTime"
        />
        <a-button
            icon="download"
            class="btn ml-20"
            type="primary"
            :loading="loadingBtn"
            @click="handleDownloadData"
        >{{ tempBtnText }}</a-button>
      </div>
    </div>
    <div slot="footer">
      <a-button @click="cancelPushModal">关闭</a-button>
    </div>
  </a-modal>
</template>

<script>
import {listDownloadExcel} from "@/untils/listDownloadExcel"
import locale from 'ant-design-vue/es/date-picker/locale/zh_CN';
import {getExpertDataApi} from "@/components/DataExpertPopup/_apis"
import moment from "moment"

export default {
  props: [
      'titleName', 'btnText', 'apiUrl', 'datePickerType', 'tableHeaderMapTextObj',
      'filterKeyList'
  ],
  data() {
    return {
      locale,
      tempBtnText: this.btnText,
      loadingBtn: false,
      showModel: false,
      times: [],
      params: {
        startTime: undefined,
        endTime: undefined,
      }
    };
  },
  methods: {
    /** 导出文件 */
    async handleDownloadData() {
      console.log(this.times, 'jxl')
      if (!this.times.length) return this.$message.warning('请选择导出日期')
      this.$loading.show()
      this.loadingBtn = true
      this.tempBtnText = '导出中...'
      this.params.startTime = new Date(this.times[0]).getTime()
      this.params.endTime = new Date(this.times[1] + ' 23:59:59').getTime()
      const res = await getExpertDataApi(this.params, this.apiUrl)
      this.$loading.hide()
      this.loadingBtn = false
      this.tempBtnText = this.btnText
      if (res.status !== '200') return this.$message.warning('导出超时，请再次尝试')
      if (!res.data.length) return this.$message.warning('暂无数据')
      // return
      listDownloadExcel(
          res.data,
          this.titleName,
          this.filterKeyList,
          `${this.times[0]}-${this.times[1]}`,
          this.tableHeaderMapTextObj
      )
    },
    /** 展示弹窗
     * @param {Object} data
     * */
    async show() {
      this.times = []
      this.params = {
        startTime: undefined,
        endTime: undefined,
      }
      this.showModel = true
    },
    /** 改变日期 */
    handleDownloadChangeTime(array, arrayStr) {
      if (arrayStr && arrayStr.length > 0) {
        this.times = arrayStr
      } else {
        this.times = []
      }
    },
    // 关闭
    cancelPushModal() {
      this.showModel = false
    },
    disabledDate(current) {
      // Can not select days after today and today
      return current > moment().endOf('day');
    },

  },
};
</script>

<style lang="scss" scoped>

</style>