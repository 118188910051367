<template>
  <a-modal v-model="pushModal" :width='800' title="修改大币种">
    <template #footer>
      <div class="flex-sp-bt-center">
        <a-popconfirm title="确定删除最近搜索" @confirm="handleDelCacheSearchList">
          <a-button type="danger" >删除最近搜索</a-button>
        </a-popconfirm>
        <div class="flex-end-center">
          <a-button class="mr-20" @click="close">取消</a-button>
          <a-button type="primary" @click="confirmPushModal">确定修改</a-button>
        </div>
      </div>
    </template>
    <a-select
        :dropdownMatchSelectWidth="false"
        :filter-option="untils.filterOption"
        :dropdownMenuStyle="{'max-height': '400px'}"
        allowClear
        showSearch
        @search='searchData'
        class="header-item"
        v-model="coinKindId"
        placeholder="请选择大币种"
        @change="handleChangeCoin($event)"
    >
      <a-select-option
          v-for="items of coinList"
          :key="items.id"
          :value="items.id"
      >{{ items.coinKindName + `【${items.id}】` + (items.coinCategory ? `---${items.coinCategory}` : '' ) }}</a-select-option>
    </a-select>
    <div class="lately-search" v-if="latelySearchBtn && latelySearchBtn.length">
      <span>最近搜索：</span>
      <a-button
          v-for="item in latelySearchBtn"
          :key="item.id"
          class="mb-10 font-weight-bold"
          @click.stop="handleLatelySearch(item)"
      >{{ item.value + ([5].includes(item.coinCategoryId) || ['花钱'].includes(item.coinCategory) ? `---${item.coinCategory}` : '' ) }}</a-button>
    </div>
  </a-modal>
</template>

<script>
import untils from "@/untils";
import {getBigCoinList} from "@/axios/apis"
export default {
  data() {
    return {
      untils,
      pushModal: false,
      coinKindId: undefined,
      tempCoinKindId: undefined,
      coinKindName: '',
      coinList: [],
      versionId: '',
      latelySearchBtn: [],
      coinKindNameCategory: undefined,
      coinKindNameCategoryId: undefined,
    };
  },
  methods: {
    /** 点击最近搜索 */
    handleLatelySearch(item) {
      this.tempCoinKindId = item.id
      this.searchData(item.value)
    },
    /**开启*/
    show(data) {
      this.latelySearchBtn = JSON.parse(localStorage.getItem('lately-search')) || []
      this.versionId = data
      this.pushModal = true;
    },
    /** 关闭 */
    close() {
      this.pushModal = false;
      this.coinKindId = undefined
      this.coinKindNameCategory = undefined
      this.coinKindNameCategoryId = undefined
    },
    /** 获取大币种列表 */
    async searchData(inner){
      const res = await getBigCoinList(inner)
      if (res.status !== '200') return;
      const {records} = res.data
      this.coinList = records
      this.coinKindId = this.coinList.find(el => el.id === this.tempCoinKindId).id
    },
    /** 确定 */
    async confirmPushModal() {

      if (!this.coinKindId) return this.$message.warning('大币种不能为空')
      this.$loading.show();
      const res = await this.axios(
          "/dq_admin/product/updateRecoCoidId",
          {
            params: {
              productId: this.versionId,
              coinId: this.coinKindId
            },
          }
      );
      this.$loading.hide();
      if (res.status !== '200') return;
      const findIndex = this.latelySearchBtn.findIndex(el => el.id === this.coinKindId)
      if (findIndex < 0) this.latelySearchBtn.unshift({
        id: this.coinKindId,
        value: this.coinKindName,
        coinCategory: this.coinKindNameCategory,
        coinCategoryId: this.coinKindNameCategoryId
      })
      if (this.latelySearchBtn && this.latelySearchBtn.length > 40) {
        this.latelySearchBtn.splice(40)
      }
      localStorage.setItem('lately-search', JSON.stringify(this.latelySearchBtn))
      this.close();
      this.$message.success(res.message || "操作成功");
      this.$emit('change', this.versionId)
    },
    handleChangeCoin(e) {
      this.coinKindId = e
      const foundData = this.coinList.find(el => el.id === e)
      this.coinKindNameCategory = foundData.coinCategory ? foundData.coinCategory : ''
      this.coinKindNameCategoryId = foundData.coinCategoryId ? foundData.coinCategoryId : ''
      this.coinKindName = foundData.coinKindName
    },
    /** 删除最近搜索 缓存 */
    handleDelCacheSearchList() {
      this.latelySearchBtn = []
      localStorage.removeItem('lately-search')
    }
  },
};
</script>

<style lang="scss" scoped>
.lately-search {
  margin-top: 20px;
  button {
    margin-right: 15px;
  }
}
.header-item {
  margin-right: 5px;
  margin-bottom: 5px;
  width: 260px;
}
</style>