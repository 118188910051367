export const productRefuseTypeList = [
    {id: 1, value: '图片原因（不清晰）'},
    {id: 2, value: '图片原因（展示不完整）'},
    {id: 3, value: '图片原因（遮挡）'},
    {id: 4, value: '图片原因（色差严重）'},
    {id: 5, value: '图片原因（带有其他平台水印）'},
    {id: 6, value: '图片原因（无实拍图）'},
    {id: 9, value: '钱币不能沾水拍照'},
    {id: 10, value: '平台暂不支持上拍（批量、纸币、现代币、机制币裸币、杂项）'},
    {id: 11, value: '评级编号错误'},
    {id: 12, value: '评级官网无图'},
    {id: 13, value: '心理价过高'},
    {id: 14, value: '必须包邮，不能满足包邮条件'},
    {id: 15, value: '存疑建议送评后上拍'},
    {id: 16, value: '标题、描述错误，或易产生歧义、争议、误导'},
    {id: 17, value: '尺寸信息错误/不完整/不准确'},
    {id: 18, value: '存疑，不建议上拍'},
    {id: 19, value: '同一拍品上拍要大于20天'},
    {id: 20, value: 'AI对版图片仅供学习不做为交易凭证'},
    {id: 101, value: '违反国家法律法规'},
    {id: 102, value: '文物争议、管制品'},
    {id: 'auto', value: '输入其他自定义原因', icon: 'edit'}
]

// 0裸币 1 PCGS  3 NGC  11 华夏  10保粹  2公博   14 轩德   16 闻德  18 园地评级
export const ratingCompanyData = [
    {value: '裸币', id: 0},
    {value: '保粹', id: 10},
    {value: '华夏', id: 11},
    {value: '公博', id: 2},
    {value: '轩德', id: 14},
    {value: '闻德', id: 16},
    {value: '园地评级', id: 18},
    {value: 'PCGS', id: 1},
    {value: 'NGC', id: 3},
]

// 10 明清朝，20 其他朝代
export const dynastyTypeData = [
    {value: '明清朝', id: 10},
    {value: '其他朝代', id: 20},
]

/** 拍品标签 */
export const auction_product_tag_list = [
    { id: 8, tagName: "稀版" },
    { id: 4, tagName: "结晶" },
    { id: 7, tagName: "传世" },
    { id: 3, tagName: "美锈" },
    { id: 6, tagName: "锉痕" },
    { id: 1, tagName: "房梁" },
    { id: 2, tagName: "黑漆古" }
]

/** 搜索心理价阶梯 */
export const price_level_list = [
    { name: '0-200', value: '0-200' },
    { name: '201-400', value: '201-400' },
    { name: '401-700', value: '401-700' },
    { name: '701-1000', value: '701-1000' },
    { name: '1000以上', value: '1000-max' },
    { name: '5000以上', value: '5000-max' },
    { name: '10000以上', value: '10000-max' },
    { name: '20000以上', value: '20000-max' },
    { name: '50000以上', value: '50000-max' },
    { name: '100000以上', value: '100000-max' },
]

/** 审核导出表头文案映射 */
export const expertTableHeaderMapTextObj = {
    reviewAdminName: '审核人名称',
    date: '审核日期',
    count: '审核数量',
}